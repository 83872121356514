import React from 'react'

function Work() {
  const WorkList = [
    {
        "name": "Jason Bradbury's Official Website",
        "image" : "assets/jasonbradbury.png",
        "link": "http://www.jasonbradbury.com"
    },
    {
      "name": "Laravel Blog",
      "image" : "assets/blog.png",
      "link": "https://github.com/JamesCheek21/blog",        
    },
    
    {
      "name": "Clothing Brand Proof of Concept",
      "image" : "assets/clothing.png",
      "link": "https://jamescheek21.github.io/Supreme"
    },
    {    
      "name":  "Tattoo Studio Front End Proof of Concept",
      "image" : "assets/tattoostudio.png",
      "link": "https://jamescheek21.github.io/TattooStudio",
    },
    {    
      "name":  "Tattoo Studio Back End Proof of Concept - Github",
      "image" : "assets/tattoostudiobe.png",
      "link": "https://github.com/JamesCheek21/TattooStudio",
    },
    {    
      "name":  "1st Year Web Authoring Assessment",
      "image" : "assets/webauthoring.png",
      "link": "https://jamescheek21.github.io/Supreme/OLD",
    },
  ]
  return (
    <div className='flex flex-wrap items-center justify-center w-full h-full py-10 overflow-y-auto workContainer'>
      {WorkList.map((item) => (
        <>
          <a href={item.link} target="_blank" style={{backgroundImage: `url(${item.image})`}} className="flex items-end pb-4 pl-4 mx-4 my-2 transition duration-500 ease-in-out bg-center bg-no-repeat bg-cover bg-blend-multiply bg-slate-300 group rounded-2xl hover:mix-blend-overlay hover:scale-105 h-56 basis-full md:basis-1/4" rel="noreferrer">
              <p className='text-4xl transition duration-500 ease-in-out drop-shadow-lg opactiy-1 md:opacity-0 group-hover:opacity-100'>{item.name}</p>
          </a>
        </>
      ))}
    </div>
  )
}

export default Work