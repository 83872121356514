import React from 'react'

function MainScreen() {
  return (
    <div style={{backgroundImage: 'url(assets/background.png)'}} className="flex flex-col items-center justify-center h-screen overflow-hidden bg-fixed bg-center bg-no-repeat bg-cover bg-main-image">
      <div className="w-10/12 md:w-1/2 flex justify-center items-center relative glass overflow-hidden py-16 px-20 rounded-2xl shadow-glass-shadow before:content-[''] before:absolute before:bg-inherit before:top-0 before:left-0 before:right-0 before:bottom-0 before:-m-5 before:shadow-inner-glass before:blur">
        <h1 className="text-5xl font-bold text-center text-white md:text-7xl title ">
          James Cheek Web & App Design
        </h1>
      </div>
    </div>
  )
}

export default MainScreen