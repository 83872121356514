import React from 'react'

function Experience() {
  return (
      <div className='justify-center w-full h-full md:px-36 px-3 py-5 md:py-10 overflow-y-auto workContainer'>
      {/* <div className='flex flex-col w-full mt-5 md:px-36 h-full overflow-y-auto md:mt-5 px-4 text-3xl md:text-4xl mx-auto workContainer'> */}
        <p>A 23 year old Freelance Web Developer with previous experience as a Junior App Developer for the Univeristy of Lincoln Student's Union.</p>
        <br />
        <p>Graduated with a Upper Second Class Honours BSc degree. Has studied web design for 5 years, with one paid freelance job as well as a multitude of projects for education and proof of concept. </p>
        <div className="text-center mt-5"><a target="_blank" className="text-center hover:text-blue-300" href="https://github.com/JamesCheek21" rel="noreferrer"><i className="text-6xl fab fa-github"></i></a></div>
        <br />
        <p className="text-4xl md:text-5xl text-center font-bold title mb-7">Skills</p>
        <p>Proficient in React Native, ReactJS, HTML5, CSS3 and JS; with experience working with Laravel, PHP and MySQL.</p>
        <br />
        <p>Obtained a Microsoft Technology Associate certification in "Introduction to Programming using HTML and CSS" in 2019 with a score of 89%</p>
        <div className='flex my-10 justify-center'> 
            <a rel="noreferrer" target="_blank" className='md:grayscale hover:grayscale-0 transition duration-500 ease-in-out' href="https://www.credly.com/users/jamescheek/badges">
                <img src="assets/MTA.png" className="w-44 md:w-64" alt="" />
            </a>
        </div>
      </div>
  )
}


export default Experience