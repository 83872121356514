import React from 'react'

const Skills = () => {
  return (
    <div className="flex flex-col w-full md:w-5/6 h-full overflow-y-auto md:mt-5 px-4 text-3xl md:text-4xl mx-auto workContainer">
        
    </div> 
  )
}

export default Skills