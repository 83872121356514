import './App.css';
import MainScreen from './components/MainScreen';
import Experience from './components/Experience';
import Work from './components/Work';
import {useState} from 'react'
import Skills from './components/Skills';

function App() {
  const [section, setSection] = useState(0);
  
  return (
    <>
    <MainScreen />
    {/* <Experience />
    <Work /> */}
    <div className="flex items-center justify-center h-[95vh] md:h-screen py-4 md:py-12 bg-gradient-to-t from-blue-500">
      <div className="justify-center w-11/12 md:w-5/6 h-full overflow-hidden text-xl font-normal rounded-xl md:text-3xl text-slate-100 bg-slate-500/30">
        <div className="flex w-full border-b-2 border-b-slate-800/20">
          <button onClick={() => setSection(0)} className="flex-1 py-8 text-center border-r hover:bg-slate-200/20 border-r-slate-800/20">
            <h1 className="text-xl font-bold title md:text-3xl">Experience</h1>
          </button>
          <button onClick={() => setSection(2)} className="flex-1 py-8 text-center border-r hover:bg-slate-200/20 border-r-slate-800/20">
            <h1 className="text-xl font-bold title md:text-3xl">Work</h1>
          </button>
          <button onClick={() => setSection(3)} className="flex-1 py-8 text-center hover:bg-slate-200/20">
            <h1 className="text-xl font-bold title md:text-3xl">Contact</h1>
          </button>
        </div>
        <div className="h-full overflow-y-auto">
          {section === 0 ? 
            <Experience />
          : section === 1 ? 
            <Skills />
          : section === 2 ? 
            <Work />
          : null}
        </div>
      </div>
    </div>
    </>
  );
}

export default App;
